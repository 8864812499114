import { createBrowserRouter } from "react-router-dom";
import { Trucks, Wagons } from "./pages";


export const router = createBrowserRouter([
    {
        path: '/',
        element: <Trucks />
    },
    {
        path: '/wagons',
        element: <Wagons />
    },
    {
        path: '/trucks',
        element: <Trucks />
    },

])