import * as React from 'react';
import { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faWeightHanging, faFileExcel, faSearch, faTruckFront } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import axios from 'axios';
import dayjs from 'dayjs';
import './styles.css';

library.add(faWeightHanging, faFileExcel, faCalendar, faSearch);
interface Wagons {
  truck_id: number;
  truck_plate: string;
  passed_date: string;
  bucket_url: string;
  all_detections: string;
  is_correct_ocr: boolean;
  input_manual: string;
}

export const Trucks: React.FC = () => {
  const [wagons, setWagons] = useState<Wagons[]>([]);
  const [filteredWagons, setFilteredWagons] = useState<Wagons[]>([]);
  const [searchWagon, setWagonSearch] = useState("");
  // const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [endDate, setEndDate] = useState<Date | undefined>(dayjs(new Date()).add(1, 'day').toDate());
  const [showNonWeighted, setShowNonWeighted] = useState(false);
  const [filtersActive, setFiltersActive] = useState(false);
  const [showWeighted, setShowWeighted] = useState(false);
  const [inputPlate, setInputPlate] = useState("")
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const today = moment().format('DD/MM/YYYY');

  const handleClearFilters = () => {
    // setSelectedDate(null);
    setShowNonWeighted(false);
    setShowWeighted(false);
    setWagonSearch("");
    setFiltersActive(false);
  };
  
  // const handleDateChange = (date: Date | null) => {
  //   setSelectedDate(date);
  //   setFiltersActive(true);
  // };

  function onChangeDateHandler(value: any) {
    setStartDate(value[0]);
    if(value.length === 1) {
      setEndDate(dayjs(value[0]).add(1, 'day').toDate())
    } else {
      setEndDate(value[1]);
    }
  }
  
  const handleOpen = () => {
    setOpen(!open);
    };

  const handleCheckboxFoundWagons = (e: any, type: string) => {
    if(type === 'found' && e.target.checked) {
      setShowWeighted(true);
      setShowNonWeighted(false);
      const filterWagons = wagons.filter((wagon) => wagon.truck_plate.toUpperCase() !== 'NOT FOUND')

      setFilteredWagons(filterWagons);
    } else if(type === 'not-found' && e.target.checked) {
      setShowWeighted(false);
      setShowNonWeighted(true);
      const filterWagons = wagons.filter((wagon) => wagon.truck_plate.toUpperCase() === 'NOT FOUND')

      setFilteredWagons(filterWagons);
    } else {
      setShowWeighted(false);
      setShowNonWeighted(false);
      setFilteredWagons(wagons);
    }
  }

  const handleDownload = async () => {
    setLoading(true);

    const response = await fetch('https://balanca-3.apigbmtech.com/trucks_excel', {
      method: 'GET'
    });

    const data = await response.blob();

    const downloadUrl = URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'report-rodovia-entrada.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setLoading(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    postMessage(event.target.value);
    console.log(event.target.value);
    setWagonSearch(event.target.value);
  };

  async function PressEnter(e: any, id: number) {
    if (e.keyCode == 13) {
      console.log(e.target.value)
      await axios.post('https://balanca-3.apigbmtech.com/post_plate', {id, plate: e.target.value})
      await fetchData()
    }
  }

  async function fetchData() {

    const startDateFormatted = dayjs(startDate).format('YYYY-MM-DD 00:00:00')
    const endDateFormatted = dayjs(endDate).format('YYYY-MM-DD 00:00:00')

    const { data } = await axios.get('https://balanca-3.apigbmtech.com/trucks', {params: {start_date: startDateFormatted, end_date: endDateFormatted} });
    const response_excel = await fetch('https://balanca-3.apigbmtech.com/trucks_excel');
    setWagons(data);
    setFilteredWagons(data);
  }

  useEffect(() => {
    if(startDate && endDate)
      fetchData();
  }, [startDate, endDate]);


  function handleChangeIsCorrectValue(id: number) {
    const output = [...wagons];
    const index = output.findIndex(w => w.truck_id === id);
    output[index].is_correct_ocr = !output[index].is_correct_ocr;
    setWagons(output);
  }

  return (
    <>
      <header className="nav-bar">
        <div className="header-logo">
          <svg width="157" height="40" viewBox="0 0 157 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M79.1948 38.5937C70.7011 38.5937 63.154 38.6144 56.1521 38.6144C52.6512 38.6144 50.7882 38.8326 48.4356 36.6849C46.7281 35.1253 45.9718 32.5113 45.9718 29.0451V12.0657C45.9718 8.35374 46.8224 5.65706 48.5238 3.97566C50.2252 2.29425 52.9489 1.45202 56.695 1.44896H79.1995V8.88435H57.2797C55.1359 8.88435 54.0641 9.9448 54.0641 12.0657L53.8762 28.8591C53.8487 29.2144 53.8991 29.5714 54.0241 29.9057C54.149 30.2399 54.3455 30.5434 54.6 30.7954C54.8546 31.0474 55.1611 31.2419 55.4987 31.3656C55.8363 31.4893 56.1969 31.5393 56.5558 31.5121C60.6623 31.5121 68.4577 31.4317 71.9725 31.4317V22.7031H62.5833V16.3243H79.1948V38.5937Z" fill="#0544A2"/>
          <path d="M80.4268 1.46733H101.862C105.363 1.46733 107.936 2.25444 109.578 3.82865C111.221 5.40286 112.043 7.7994 112.044 11.0183C112.044 13.1407 111.58 14.865 110.652 16.1911C109.82 17.4412 108.624 18.4125 107.223 18.9774C110.116 19.7568 112.081 21.6664 113.119 24.7061C113.496 25.9442 113.677 27.2329 113.655 28.526C113.655 31.9945 112.832 34.5418 111.188 36.1681C109.544 37.7944 106.972 38.6075 103.472 38.6075H80.4268V1.46733ZM103.509 12.0795C103.509 9.95705 102.437 8.89583 100.293 8.89583H88.7488V16.3243H100.3C102.444 16.3243 103.516 15.2631 103.516 13.1407L103.509 12.0795ZM104.005 26.9342C104.005 24.8133 102.934 23.7528 100.79 23.7528H88.7488V31.179H100.79C102.934 31.179 104.005 30.1178 104.005 27.9954V26.9342Z" fill="#0544A2"/>
          <path d="M147.353 17.9162L139.85 31.179H132.35L124.846 17.9162V38.6075H115.197V1.46733H125.382L136.101 21.1021L146.817 1.46733H157V38.6075H147.353V17.9162Z" fill="#0544A2"/>
          <path d="M38.1996 20.0202C38.2267 22.3848 37.7796 24.7313 36.8844 26.9236C35.9891 29.116 34.6635 31.1107 32.9842 32.7923C31.3048 34.4739 29.3052 35.809 27.1011 36.7201C24.8971 37.6312 22.5323 38.1004 20.1438 38.1004C17.7553 38.1004 15.3906 37.6312 13.1865 36.7201C10.9824 35.809 8.98279 34.4739 7.30347 32.7923C5.62415 31.1107 4.29848 29.116 3.40323 26.9236C2.50799 24.7313 2.06096 22.3848 2.08803 20.0202C2.08968 16.0672 3.41458 12.2262 5.85553 9.09798C8.29648 5.96976 11.7158 3.73072 15.5787 2.73108C19.4416 1.73144 23.5302 2.02757 27.205 3.57315C30.8799 5.11873 33.9337 7.8266 35.8888 11.2732L37.4757 9.63317C34.8082 5.2759 30.5615 2.09091 25.6026 0.728393C20.6437 -0.634129 15.3465 -0.0714438 10.7924 2.30158C6.23836 4.67461 2.77076 8.67904 1.09753 13.4974C-0.575697 18.3158 -0.328384 23.5847 1.78898 28.2287C3.90635 32.8726 7.73411 36.5414 12.4909 38.486C17.2476 40.4307 22.5747 40.5045 27.3845 38.6926C32.1944 36.8806 36.1245 33.3194 38.3724 28.736C40.6203 24.1525 41.0165 18.8925 39.4803 14.0296L37.7101 15.8672C38.0364 17.2279 38.2007 18.6217 38.1996 20.0202Z" fill="#0544A2"/>
          <path d="M19.442 32.6744C19.2915 32.6728 19.1428 32.6408 19.0052 32.5804C18.8676 32.5199 18.7438 32.4323 18.6416 32.3229L7.59583 20.4383C7.39862 20.225 7.29504 19.943 7.30788 19.6542C7.32071 19.3653 7.44891 19.0934 7.66427 18.8981C7.87963 18.7029 8.16451 18.6003 8.45624 18.613C8.74797 18.6257 9.02265 18.7527 9.21986 18.9659L19.4606 29.9915L40.9349 7.71058C41.0326 7.59984 41.152 7.50983 41.2858 7.446C41.4195 7.38216 41.565 7.34582 41.7134 7.33916C41.8617 7.3325 42.0099 7.35567 42.149 7.40725C42.2881 7.45884 42.4152 7.53779 42.5226 7.63933C42.63 7.74087 42.7155 7.8629 42.774 7.99805C42.8325 8.13321 42.8627 8.2787 42.8629 8.42574C42.863 8.57278 42.8331 8.71833 42.7749 8.8536C42.7167 8.98887 42.6314 9.11107 42.5242 9.21282L20.2517 32.3413C20.1469 32.4487 20.021 32.5337 19.8817 32.591C19.7425 32.6483 19.5928 32.6767 19.442 32.6744Z" fill="#0544A2"/>
          <path d="M41.7121 10.5933C42.9858 10.5933 44.0183 9.57109 44.0183 8.3101C44.0183 7.04911 42.9858 6.02688 41.7121 6.02688C40.4385 6.02688 39.406 7.04911 39.406 8.3101C39.406 9.57109 40.4385 10.5933 41.7121 10.5933Z" fill="#0544A2"/>
          <path d="M8.27327 21.8463C9.54691 21.8463 10.5794 20.8241 10.5794 19.5631C10.5794 18.3021 9.54691 17.2799 8.27327 17.2799C6.99964 17.2799 5.96715 18.3021 5.96715 19.5631C5.96715 20.8241 6.99964 21.8463 8.27327 21.8463Z" fill="#0544A2"/>
          </svg>
        </div>
        <a href="https://painel-pesagem-tgg.gbmtech.com.br/wagons"></a>
        <div className="buttons">

        <DatePicker
          className='date-picker'
          dateFormat="dd/MM/yyyy"
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          placeholderText={`${today}`}
          onChange={onChangeDateHandler}
        />
        <span className="icon-wrapper">
          <FontAwesomeIcon icon={faCalendar} />
        </span>

        {filtersActive && (
          <div className="filter-controls">
            <button onClick={handleClearFilters}>
              <span>Clear Filters</span>
              <span>
                <FontAwesomeIcon icon={faCalendar} />
              </span>
            </button>
          </div>
        )}    
        
        <div className="dropdown">
          <button onClick={handleOpen}>
            <FontAwesomeIcon className='dropdown-icon' icon={faTruckFront}/>Filtros</button>
          {open ? (
            <>
            <ul className="menu">
                <li className="menu-item">
                  <div className='checkbox'>
                    <input className="checkbox-not-weighted" type='checkbox' placeholder='Encontrados' checked={showWeighted} onChange={(e) => handleCheckboxFoundWagons(e, 'found')} />
                    <label>Encontrados</label>
                  </div>
                </li>
                <li className="menu-item">
                  <div className='checkbox'>
                    <input className="checkbox-weighted" type='checkbox' placeholder='Não encontrados' checked={showNonWeighted} onChange={(e) => handleCheckboxFoundWagons(e, 'not-found')} />
                    <label>Não Encontrados</label>
                  </div>
                </li>
              </ul></>) : null}
            </div>
            <button className='download-excel' onClick={handleDownload}>
            {loading ? 'Downloading...' : <><FontAwesomeIcon className='excel-icon' icon={faFileExcel} /> Exportar Excel</>}
              </button>
          </div>   
      </header>
      <div className="container-header">
        <div className="title-founded-wagons">
          <strong>Caminhões encontrados: {filteredWagons.length}</strong> 
        </div>
        <input className="search-bar" type="text" placeholder="Buscar caminhão por placa..." onChange={handleChange}></input>
        <button className="to-wagons"><a href="https://painel-pesagem-tgg.gbmtech.com.br/wagons">Ferrovia</a></button>
      </div>
      <div className="div-wagon-results">
          <table className="table table-wagons">
            <thead>
                <tr className="table-header">
                  <th scope="col">Placa</th>
                  <th scope="col">Foto</th>
                  <th scope="col">Detecções</th>
                  <th scope="col">Data de passagem</th>
                  <th scope="col">Correto</th>
                  <th scope="col">Correção manual</th>
                </tr>
              </thead>
              <tbody>
                  {filteredWagons.map((wagon) => { 
                    if (searchWagon === "" || wagon.truck_plate.toLocaleLowerCase().includes(searchWagon.toLocaleLowerCase())) {

                    return (
                      <tr key={wagon.truck_id} className="table-head">
                        <td scope="row">{wagon.truck_plate}</td>
                        <td>
                          <a href={wagon.bucket_url}>
                            <img src={wagon.bucket_url} className="table-image"></img>
                          </a>
                        </td>

                          {wagon.all_detections ? 
                            <td scope="row">{wagon.all_detections.replace(/[\[\]"']/g, '').replace(/,/g, ',')}</td> :
                            <td scope="row">Sem detecções</td>}

                        <td scope="row">{format(new Date(wagon.passed_date), "dd/MM/yyyy kk:mm:ss")}</td>
                        
                        <td scope="row"><input
                          type="checkbox"
                          checked={wagon.is_correct_ocr}
                          onChange={(e) => handleChangeIsCorrectValue(wagon.truck_id)}
                          />
                        </td>

                        {wagon.is_correct_ocr || wagon.input_manual ? 
                        <td scope="row">{wagon.input_manual}</td> :
                        <td scope="row"><input type="text" onKeyDown={(e) => PressEnter(e, wagon.truck_id)} maxLength={7}></input></td> }
                      </tr>
                      )
                        }
                  })}
              </tbody>
          </table>
        </div>
    </>
  );
};

export default Trucks;